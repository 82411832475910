import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExtractFormControlTypes } from '@app/shared/models/types/utility-types';
import { DashboardFilterForm } from '@app/dashboard/dashboard-filter/dashboard-filter.component';
import { Tag } from '@app/shared/models/tag.interface';
import { Floor } from '@app/shared/models/floor.interface';
import { Building } from '@app/shared/models/building.interface';

export type DashboardFilterCriteria = ExtractFormControlTypes<DashboardFilterForm>;

@Injectable({
  providedIn: 'root'
})
export class DashboardStateService {
  private _dashboardFilterState = new BehaviorSubject<DashboardFilterCriteria>(null);
  private tags: Tag[] = [];
  private floors: Floor[] = [];
  private building: Building;
  private tagMap = new Map<number, Tag>();

  initializeState(state: { floors: Floor[]; tags: Tag[]; building: Building }): void {
    const { floors, tags, building } = state;
    this.floors = floors;
    this.tags = tags;
    this.building = building;
    this.tags.forEach((tag) => this.tagMap.set(tag.id, tag));
    this._dashboardFilterState.next({ startDate: null, endDate: null, tags: [], floors: [] });
  }

  isEmptyState(): boolean {
    const { startDate, endDate, tags, floors } = this._dashboardFilterState.getValue();
    return !startDate && !endDate && !tags.length && !floors.length;
  }

  setTags(value: Tag[]): void {
    this.tags = value;
  }

  saveFilter(value: any): void {
    this._dashboardFilterState.next(value);
  }

  get currentBuilding(): Building {
    return this.building;
  }

  get dashboardFilterState$(): Observable<DashboardFilterCriteria> {
    return this._dashboardFilterState.asObservable();
  }

  get currentFilterState(): DashboardFilterCriteria {
    return this._dashboardFilterState.getValue();
  }

  get tagIdToTagMap(): Map<number, Tag> {
    return this.tagMap;
  }

  get allTags(): Tag[] {
    return this.tags;
  }

  get allFloors(): Floor[] {
    return this.floors;
  }
}
