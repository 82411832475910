import { Building } from '@app/shared/models/building.interface';
import { TimeUtils } from '@app/shared/utils/time.utils';
import { subMonths } from 'date-fns';

export type GroupBy = 'BUILDING' | 'NODE' | 'FLOOR' | 'TAG';

export type DataTypeNames =
  | 'MOTION'
  | 'AMBIENT_LIGHT_LEVEL'
  | 'LIGHT_OUTPUT_LEVEL'
  | 'CIRCADIAN_LIGHT_OUTPUT_LEVEL'
  | 'COLOUR_TEMPERATURE'
  | 'CONNECTION_QUALITY'
  | 'ENERGY_CONSUMPTION'
  | 'PRESENCE'
  | 'SCENE'
  | 'A2D_SIGNAL_CHANGE_RATE'
  | 'A2D_SIGNAL_MAX_DELTA'
  | 'REFERENCE_VOLTAGE_CHANGE'
  | 'CURRENT_REFERENCE_VOLTAGE'
  | 'LMSA_EVENT_COUNT'
  | 'LMSA_EVENT_PORTION'
  | 'SUPPRESSED_MOTION_EVENTS_COUNT'
  | 'MAX_PIR_MOVEMENT_CODE'
  | 'TEMPERATURE'
  | 'PRESSURE'
  | 'HUMIDITY'
  | 'FUJI_AMBIENT_LIGHT_LEVEL'
  | 'SOUND'
  | 'CO2'
  | 'BALLAST_FAILURE'
  | 'LAMP_FAILURE'
  | 'LAMP_POWER_ON'
  | 'OUTPUT_LIMIT_ERROR'
  | 'FADE_READY'
  | 'RESET_STATE'
  | 'SHORT_ADDRESS_MISSING'
  | 'POWER_FAILURE_SINCE_LAST_COMMAND'
  | 'DISCONNECTED'
  | 'ACTIVE_ENERGY'
  | 'ACTIVE_POWER'
  | 'CONTROL_GEAR_OPERATING_TIME'
  | 'CONTROL_GEAR_START_COUNTER'
  | 'CONTROL_GEAR_EXTERNAL_SUPPLY_VOLTAGE'
  | 'CONTROL_GEAR_EXTERNAL_SUPPLY_VOLTAGE_FREQUENCY'
  | 'CONTROL_GEAR_POWER_FACTOR'
  | 'CONTROL_GEAR_OVERALL_FAILURE_CONDITION'
  | 'CONTROL_GEAR_OVERALL_FAILURE_CONDITION_COUNTER'
  | 'CONTROL_GEAR_EXTERNAL_SUPPLY_UNDER_VOLTAGE'
  | 'CONTROL_GEAR_EXTERNAL_SUPPLY_UNDER_VOLTAGE_COUNTER'
  | 'CONTROL_GEAR_EXTERNAL_SUPPLY_OVER_VOLTAGE'
  | 'CONTROL_GEAR_EXTERNAL_SUPPLY_OVER_VOLTAGE_COUNTER'
  | 'CONTROL_GEAR_OUTPUT_POWER_LIMITATION'
  | 'CONTROL_GEAR_OUTPUT_POWER_LIMITATION_COUNTER'
  | 'CONTROL_GEAR_THERMAL_DERATING'
  | 'CONTROL_GEAR_THERMAL_DERATING_COUNTER'
  | 'CONTROL_GEAR_THERMAL_SHUTDOWN'
  | 'CONTROL_GEAR_THERMAL_SHUTDOWN_COUNTER'
  | 'CONTROL_GEAR_TEMPERATURE'
  | 'CONTROL_GEAR_OUTPUT_CURRENT_PERCENT'
  | 'LIGHT_SOURCE_START_COUNTER_RESETTABLE'
  | 'LIGHT_SOURCE_START_COUNTER'
  | 'LIGHT_SOURCE_ON_TIME_RESETTABLE'
  | 'LIGHT_SOURCE_ON_TIME'
  | 'LIGHT_SOURCE_VOLTAGE'
  | 'LIGHT_SOURCE_CURRENT'
  | 'LIGHT_SOURCE_OVERALL_FAILURE_CONDITION'
  | 'LIGHT_SOURCE_OVERALL_FAILURE_CONDITION_COUNTER'
  | 'LIGHT_SOURCE_SHORT_CIRCUIT'
  | 'LIGHT_SOURCE_SHORT_CIRCUIT_COUNTER'
  | 'LIGHT_SOURCE_OPEN_CIRCUIT'
  | 'LIGHT_SOURCE_OPEN_CIRCUIT_COUNTER'
  | 'LIGHT_SOURCE_THERMAL_DERATING'
  | 'LIGHT_SOURCE_THERMAL_DERATING_COUNTER'
  | 'LIGHT_SOURCE_THERMAL_SHUTDOWN'
  | 'LIGHT_SOURCE_THERMAL_SHUTDOWN_COUNTER'
  | 'LIGHT_SOURCE_TEMPERATURE';

export type DateInterval = 'FIVE_MINUTES' | 'HOURLY' | 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY';

export type AggregationType =
  | 'AVERAGE'
  | 'COUNT'
  | 'FIRST'
  | 'SUM'
  | 'COUNT_PERCENTAGE'
  | 'ENERGY_SAVING'
  | 'CHUNK_AVERAGE';

export type QueryType = 'TIME_SERIES' | 'PER_NODE';

export interface InfluxDataType {
  name: DataTypeNames;
  tableName: string;
  publiclyAvailable: boolean;
  includeGapsAsZero: boolean;
  unit: string;
  aggregationType: string;
  liveUnit?: string;
  aggregationSource?: string;
  numberPerSecond?: number;
}

export interface Unit {
  label: string;
  suffix: string;
  maximum: number;
  bitsPerValue: number;
}

export interface InfluxQueryOutline {
  start?: number;
  end?: number;
  type: DataTypeNames;
  queryType?: QueryType;
  dateInterval: DateInterval;
  aggregationType?: AggregationType;
  includeDriverAddress: boolean;
  groupBy: GroupBy;
  buildingId: number;
  floorIds: number[];
  tagIds: number[];
  sensorNodeIds: number[];
  gatewayIds: number[];
  zoneId: string;
}

export function getDefaultInfluxQueryOutlineForBuilding(
  building: Building,
  shouldApplyDate?: boolean
): InfluxQueryOutline {
  const localDay = TimeUtils.adjustDateToTimezone(new Date(), building.timeZone);
  const utcOffset = TimeUtils.getUTCOffset(building.timeZone);
  const oneDayMinusOneSecondInMillisecond = 86400000 - 1000;

  const todayFromEpoch =
    Date.UTC(localDay.getFullYear(), localDay.getMonth(), localDay.getDate()) +
    oneDayMinusOneSecondInMillisecond +
    utcOffset;

  const defaultStartDate =
    shouldApplyDate === false || shouldApplyDate == null
      ? 0
      : subMonths(todayFromEpoch - oneDayMinusOneSecondInMillisecond, 1).getTime();

  return {
    start: defaultStartDate,
    end: todayFromEpoch,
    type: 'ENERGY_CONSUMPTION',
    dateInterval: 'YEARLY',
    includeDriverAddress: false,
    groupBy: 'TAG',
    buildingId: building.id,
    floorIds: [],
    tagIds: [],
    sensorNodeIds: [],
    gatewayIds: [],
    zoneId: building.timeZone
  };
}

export function getDefaultMetricsInfluxQueryOutlineForBuilding(building: Building): InfluxQueryOutline {
  return {
    type: 'LIGHT_OUTPUT_LEVEL',
    queryType: 'TIME_SERIES',
    dateInterval: 'HOURLY',
    groupBy: 'BUILDING',
    buildingId: building.id,
    floorIds: [],
    sensorNodeIds: [],
    tagIds: [],
    gatewayIds: [],
    zoneId: building.timeZone,
    includeDriverAddress: false
  };
}
