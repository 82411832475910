import { Inject, Injectable } from '@angular/core';
import { Resource } from './resource';
import { IGlobalAuthorityRequest, Status } from '@app/shared/models/user-global-authority-request-interface';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalAuthorityRequestResource extends Resource<IGlobalAuthorityRequest, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/global-auth`);
  }

  public getGlobalAuthorityRequestsByStatus(status: Status): Observable<IGlobalAuthorityRequest[]> {
    return this.httpClient.get<IGlobalAuthorityRequest[]>(`${this.baseUrl}/request/${status}`);
  }

  public setGlobalAuthorityRequestStatus(id: number, fromStatus: Status, toStatus: Status): Observable<Status> {
    return this.httpClient.put<Status>(`${this.baseUrl}/request/${id}/from/${fromStatus}/to/${toStatus}`, {});
  }
}
