import { UrlHandlingStrategy, UrlTree } from '@angular/router';
import * as ROUTES from '@common/route-constants';

const migratedRoutes: string[] = [
  ROUTES.GLOBAL_ADMIN_ROUTE.regex,
  ROUTES.GLOBAL_ADMIN_USER_ROUTE.regex,
  ROUTES.GLOBAL_ADMIN_MANAGING_COMPANY_ROUTE.regex,
  ROUTES.GLOBAL_USER_INFORMATION_ROUTE.regex,
  ROUTES.GLOBAL_ADMIN_EMERGENCY_LIGHTING_CALENDAR_ROUTE.regex,
  ROUTES.GLOBAL_ADMIN_PACKET_STREAMER_ROUTE.regex,
  ROUTES.GLOBAL_ADMIN_OTAP_UPGRADES.regex,
  ROUTES.GLOBAL_ADMIN_OTAP_UPGRADES_GATEWAY.regex,
  ROUTES.ADMIN_ROUTE.regex,
  ROUTES.BUILDING_ADMIN_ROUTE.regex,
  ROUTES.GATEWAYS_ADMIN_ROUTE.regex,
  ROUTES.FLOORS_ADMIN_ROUTE.regex,
  ROUTES.USERS_ADMIN_ROUTE.regex,
  ROUTES.TOKENS_ADMIN_ROUTE.regex,
  ROUTES.MOBILE_TOKENS_ADMIN_ROUTE.regex,
  ROUTES.ROLES_ADMIN_ROUTE.regex,
  ROUTES.DDL_ROUTE.regex,
  ROUTES.BUILDINGS_ROUTE.regex,
  ROUTES.NEW_BUILDING_ROUTE.regex,
  ROUTES.CIRCADIAN_CURVES_ROUTE.regex,
  ROUTES.TENANT_GROUPS_ADMIN_ROUTE.regex,
  ROUTES.CALENDAR_ADMIN_ROUTE.regex,
  ROUTES.EMERGENCY_LIGHTING_ADMIN_ROUTE.regex,
  // ROUTES.ANALYTICS_ROUTE.regex,
  ROUTES.EMERGENCY_LIGHTING_BASE_ROUTE.regex,
  ROUTES.EMERGENCY_LIGHTING_SCHEDULES_ROUTE.regex,
  ROUTES.EMERGENCY_LIGHTING_LOGS_ROUTE.regex,
  ROUTES.EMERGENCY_LIGHTING_REPORTS_ROUTE.regex,
  ROUTES.EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE.regex,
  ROUTES.MAP_NODES_ROUTE.regex,
  ROUTES.SENSORNODES_ROUTE.regex,
  ROUTES.DASHBOARD_ROUTE.regex,
  ROUTES.DASHBOARD_GENERAL_ROUTE.regex,
  ROUTES.DASHBOARD_ENERGY_ROUTE.regex,
  ROUTES.DASHBOARD_OCCUPANCY_ROUTE.regex,
  ROUTES.DASHBOARD_MAINTENANCE_ROUTE.regex,
  ROUTES.DASHBOARD_METRICS_ROUTE.regex
];

export class CustomHandlingStrategy implements UrlHandlingStrategy {
  shouldProcessUrl(url: UrlTree): boolean {
    return this.isUpgradedRoute(url.toString());
  }

  extract(url: UrlTree): UrlTree {
    return url;
  }

  merge(newUrlPart: UrlTree, rawUrl: UrlTree): UrlTree {
    return newUrlPart;
  }

  private isUpgradedRoute(url: string): boolean {
    // strip off any query params, if any, before checking the base route
    const baseRoute = url.split('?')[0];
    // Added $ to the end of the route to not claim un-migrated child paths
    return migratedRoutes.some((route) => new RegExp(route + '$').test(baseRoute.toString()));
  }
}
