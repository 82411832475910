export enum NodeType {
  SN3 = 'SN3',
  PN = 'PN',
  HIM84 = 'HIM84',
  HCD405 = 'HCD405'
}

export function getNodeTypes(): NodeType[] {
  return [NodeType.SN3, NodeType.PN, NodeType.HIM84, NodeType.HCD405];
}
