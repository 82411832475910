import { NodesController } from '../nodes/NodesController';
import { LightingConfigurationService } from '@angularjs/or/services/LightingConfigurationService';
import { FloorService } from '@angularjs/or/services/FloorService';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { INodesParamsService } from '../nodes/NodesConfig';
import { IObservable, IObservableModifiable } from '@angularjs/or/util/IObservable';
import { ILocationContext, ITagContext } from '@angularjs/or/api/query/outline/context/IContext';
import { IQueryOutlineBuilder } from '@angularjs/or/api/query/outline/IQueryOutlineBuilder';
import { UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { LightingConfiguration } from '@angularjs/or/api/building/LightingConfiguration';
import angular, { ITimeoutService } from 'angular';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';
import { TenantService } from '@app/shared/services/building/tenant.service';
import { FeatureService } from '@app/shared/services/feature.service';
import { SecurityService } from '@angularjs/or/angular/services/SecurityService';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { TagService } from '@angularjs/or/services/TagService';

export class LightingConfigurationController extends NodesController {
  public isTagPanelActive = false;
  public isConfigurationPanelActive = true;
  public isContainerPanelActive = true;
  public selectedNodes;
  private pendingNodes: number[] = [];
  private delayBetweenRetries = 5000; // milliseconds
  private retires = 7;
  private nodeLoadedToPanel: FloorplanSensorNode;

  constructor(
    $routeParams: INodesParamsService,
    $scope: ng.IScope,
    buildingService: IBuildingService,
    floorService: FloorService,
    nodesService: SensorNodeService,
    tenantService: TenantService,
    navigationService: NavigationService,
    locationContext: IObservableModifiable<ILocationContext>,
    outlineBuilder: IObservable<IQueryOutlineBuilder>,
    mysectionInfo: NavigationSectionInfo,
    uiRefreshService: UIRefreshService,
    private timeout: ITimeoutService,
    private lightingConfigurationService: LightingConfigurationService,
    featureService: FeatureService,
    tagService: TagService,
    tagContext: IObservableModifiable<ITagContext>,
    securityService: SecurityService
  ) {
    super(
      $routeParams,
      $scope,
      buildingService,
      floorService,
      nodesService,
      tenantService,
      navigationService,
      locationContext,
      outlineBuilder,
      mysectionInfo,
      uiRefreshService,
      featureService,
      tagService,
      tagContext,
      securityService
    );
    this.selectedNodes = nodesService.selectedNodes.value();
  }
  public querySelectedNode(): void {
    if (this.selectedSensorNodes.length !== 1) {
      alert('One and only one node must be selected to be able to operate!');
      return;
    }
    const node = this.selectedSensorNodes[0];
    this.addToPendingNodes(node.id);
    const lastUpdatedTime = this.findLastTime(node.lightingConfiguration);

    this.lightingConfigurationService
      .queryNode(node.id, this.buildingId)
      .then(() => {
        this.getNodeWithRetries(node, lastUpdatedTime, this.retires);
      })
      .catch((e) => {
        console.error(e);
        alert('An error happened for the query command!');
        this.removeFromPendingNodes(node.id);
      });
  }

  private updateNodeAndPanel(node: SensorNode, update: LightingConfiguration): void {
    this.updateLightingConfiguration(node, update);
    if (this.selectedSensorNodes.length === 1 && this.selectedSensorNodes[0] == node)
      this.lightingConfigurationService.updateLightingConfigPanel(update);
  }

  private getNodeWithRetries(node: SensorNode, lastUpdatedTime: Date, retries: number): void {
    if (retries <= 0) {
      if (this.isPartiallyUpdated(node.lightingConfiguration, lastUpdatedTime))
        alert('The sensor node was partially updated.');
      else alert('The time expired with no result.');
      this.removeFromPendingNodes(node.id);
      return;
    }
    this.lightingConfigurationService
      .getNode(node.id, this.buildingId)
      .then((result) => {
        result = this.lightingConfigurationService.convertTimestampsToDates(result);
        if (result && this.isQueryResultComplete(result, lastUpdatedTime)) {
          this.removeFromPendingNodes(node.id);
          this.updateNodeAndPanel(node, result);
          alert('The data was updated for node ' + node.id);
        } else {
          if (result) {
            this.updateNodeAndPanel(node, result);
          }
          this.timeout(() => {
            this.getNodeWithRetries(node, lastUpdatedTime, retries - 1);
          }, this.delayBetweenRetries);
        }
      })
      .catch((e) => {
        console.error(e);
        alert('Error in communication with the Portal');
        this.removeFromPendingNodes(node.id);
        return;
      });
  }

  private updateLightingConfiguration(node: SensorNode, update: LightingConfiguration): void {
    // this.nodesService.getRegisteredListedNode(node.id).lightingConfiguration = angular.copy(update);
    // this.nodesService.getRegisteredFloorplanNode(node.id).lightingConfiguration = angular.copy(update);
    node.lightingConfiguration = angular.copy(update);
    if (node.lightingConfiguration.scene && node.lightingConfiguration.scene.value != null) {
      node.scene = node.lightingConfiguration.scene.value; // Update the Scene on the node to the same as the lighting config as it is the latest
    } else {
      // No scene data on LightingConfig update, set to scene from node
      node.lightingConfiguration.scene = { value: node.scene, updatedAt: null };
    }
    this.scope.$apply();
  }

  public get selectedSensorNodes(): FloorplanSensorNode[] {
    const nodes = this.selectedNodes;
    if (nodes.length === 1) {
      if (nodes[0] !== this.nodeLoadedToPanel) {
        this.nodeLoadedToPanel = nodes[0];
        this.lightingConfigurationService.updateLightingConfigPanel(this.nodeLoadedToPanel.lightingConfiguration);
      }
    } else {
      if (this.nodeLoadedToPanel != null) {
        this.nodeLoadedToPanel = null;
        this.lightingConfigurationService.updateLightingConfigPanel(null);
      }
    }
    return nodes;
  }

  public get isSelectedNodeReady(): boolean {
    const isReady =
      this.selectedSensorNodes.length === 1 &&
      this.pendingNodes.indexOf(this.selectedSensorNodes[0].id, 0) === -1 &&
      this.selectedSensorNodes[0].properlyMapped &&
      this.selectedSensorNodes[0].connected &&
      !this.selectedSensorNodes[0].isPassiveNode;
    this.lightingConfigurationService.setConfigurable(isReady);
    return isReady;
  }

  public isNodePending(id: number): boolean {
    return this.pendingNodes.indexOf(id, 0) > -1;
  }
  private addToPendingNodes(id: number): void {
    if (!this.isNodePending(id)) {
      this.pendingNodes.push(id);
    }
  }

  private removeFromPendingNodes(id: number): void {
    if (this.isNodePending(id)) {
      this.pendingNodes.splice(this.pendingNodes.indexOf(id, 0), 1);
    }
  }

  public isSelectedNodePending(): boolean {
    return this.selectedSensorNodes.length === 1 && this.isNodePending(this.selectedSensorNodes[0].id);
  }

  private findLastTime(config: LightingConfiguration): Date {
    let lastTime = new Date(0);
    if (config != null) {
      if (config.zoneUpdatedAt != null && config.zoneUpdatedAt > lastTime) lastTime = config.zoneUpdatedAt;
      if (config.maxLightLevelUpdatedAt != null && config.maxLightLevelUpdatedAt > lastTime)
        lastTime = config.maxLightLevelUpdatedAt;
      if (config.minLightLevelUpdatedAt != null && config.minLightLevelUpdatedAt > lastTime)
        lastTime = config.minLightLevelUpdatedAt;
      if (config.lowLightLevelUpdatedAt != null && config.lowLightLevelUpdatedAt > lastTime)
        lastTime = config.lowLightLevelUpdatedAt;
      if (config.dwellTimeUpdatedAt != null && config.dwellTimeUpdatedAt > lastTime)
        lastTime = config.dwellTimeUpdatedAt;
      if (config.lowLightTimeUpdatedAt != null && config.lowLightTimeUpdatedAt > lastTime)
        lastTime = config.lowLightTimeUpdatedAt;
      if (config.scene != null && config.scene.updatedAt != null && config.scene.updatedAt > lastTime)
        lastTime = config.scene.updatedAt;
    }
    return lastTime;
  }

  private isQueryResultComplete(config: LightingConfiguration, lastTime: Date): boolean {
    return (
      config.zoneUpdatedAt != null &&
      config.zoneUpdatedAt > lastTime &&
      config.maxLightLevelUpdatedAt != null &&
      config.maxLightLevelUpdatedAt > lastTime &&
      config.minLightLevelUpdatedAt != null &&
      config.minLightLevelUpdatedAt > lastTime &&
      config.lowLightLevelUpdatedAt != null &&
      config.lowLightLevelUpdatedAt > lastTime &&
      config.dwellTimeUpdatedAt != null &&
      config.dwellTimeUpdatedAt > lastTime &&
      config.lowLightTimeUpdatedAt != null &&
      config.lowLightTimeUpdatedAt > lastTime &&
      config.scene != null &&
      config.scene.updatedAt != null
    );
    // as the scene timestamp is updated differently it shouldn't be engaged;
  }

  private isPartiallyUpdated(config: LightingConfiguration, lastTime: Date): boolean {
    return (
      config != null &&
      ((config.zoneUpdatedAt != null && config.zoneUpdatedAt > lastTime) ||
        (config.maxLightLevelUpdatedAt != null && config.maxLightLevelUpdatedAt > lastTime) ||
        (config.minLightLevelUpdatedAt != null && config.minLightLevelUpdatedAt > lastTime) ||
        (config.lowLightLevelUpdatedAt != null && config.lowLightLevelUpdatedAt > lastTime) ||
        (config.dwellTimeUpdatedAt != null && config.dwellTimeUpdatedAt > lastTime) ||
        (config.lowLightTimeUpdatedAt != null && config.lowLightLevelUpdatedAt > lastTime) ||
        (config.scene && config.scene.updatedAt != null && config.scene.updatedAt > lastTime))
    );
  }
}
