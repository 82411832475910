import { Injectable } from '@angular/core';
import { GlobalAuthorityRequestResource } from '@app/shared/resources/global-authority-request-resource.service';
import { GlobalAuthorityRequest, Status } from '@app/shared/models/user-global-authority-request-interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalAuthorityRequestService {
  constructor(private globalAuthorityRequestResource: GlobalAuthorityRequestResource) {}

  public getGlobalAuthorityRequestsByStatus(status: Status): Observable<GlobalAuthorityRequest[]> {
    return this.globalAuthorityRequestResource.getGlobalAuthorityRequestsByStatus(status);
  }

  public setGlobalAuthorityRequestStatus(id: number, fromStatus: Status, toStatus: Status): Observable<Status> {
    return this.globalAuthorityRequestResource.setGlobalAuthorityRequestStatus(id, fromStatus, toStatus);
  }
}
