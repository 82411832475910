<div id="orAdministrationDdl">
  <div class="or-content">
    @if(ddlForm) {
      <form [formGroup]="ddlForm">
        <div class='or-content-header'>
          <ul class='or-inline-items or-pull-left'>
            <li class='or-inline-item'>
            </li>
          </ul>
        </div>
        <div class="or-content-body">
          <div class="or-switch-container or-flex">
            <h2 class="or-form-title">DDL Enabled</h2>
            <label class="switch">
              <input type="checkbox" formControlName="modeEnabled" (change)="updateDdlMode()">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="or-flex">
            <div class="or-input-container">
              <h1 class="or-label or-label-above">Outdoor Sensor Address</h1>
              <input
                class="or-input"
                type="number"
                placeholder="Id"
                formControlName="address"
              />
            </div>
            <div class="or-input-container">
              <h1 class="or-label or-label-above">DDL Luminaires</h1>
              <select
                class="or-input"
                formControlName="ddlTagId"
                name="repeatSelect">
                @for (ddlTag of ddlTags ; track ddlTag.id) {
                  <option value="{{ddlTag.id}}">
                    {{ddlTag.name}}
                  </option>
                }
              </select>
            </div>
          </div>
          <br/>
          <div>
            <li class="or-form-action">
              <button
                class="or-button or-primary or-positive"
                type="submit"
                data-cy="save-ddl"
                (click)="saveButtonPressed()"
                [disabled]='ddlForm.invalid || ddlForm.pristine || !ddlMode.enabled'>
                <span class="or-icon or-small or-inverse or-icon-checkmark"></span>
                <span class="or-button-label">Save</span>
              </button>
            </li>
          </div>
        </div>
      </form>
    }
  </div>
</div>
