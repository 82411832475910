import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { DdlMode, DdlTag, DdlSetting } from '@app/shared/models/ddl-setting.interface';

@Injectable({
  providedIn: 'root'
})
export class DdlResource {
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject(environmentToken) environment: Environment) {
    this.baseUrl = environment.apiUrl + environment.apiPath;
  }

  getDdlSetting(buildingId: number): Observable<DdlSetting> {
    return this.http.get<DdlSetting>(`${this.baseUrl}/building/${buildingId}/ddl-building-setting`);
  }

  createSetting(ddlSetting: DdlSetting): Observable<DdlSetting> {
    return this.http.post<DdlSetting>(`${this.baseUrl}/ddl-building-setting`, ddlSetting);
  }

  updateDdlSetting(ddlSetting: DdlSetting, settingId: number): Observable<DdlSetting> {
    return this.http.put<DdlSetting>(`${this.baseUrl}/ddl-building-setting/${settingId}`, ddlSetting);
  }

  getDdlMode(buildingId: number): Observable<DdlMode> {
    return this.http.get<DdlMode>(`${this.baseUrl}/building/${buildingId}/ddl-mode`);
  }

  createDdlMode(buildingId: number): Observable<DdlMode> {
    return this.http.post<DdlMode>(`${this.baseUrl}/ddl-mode`, { enabled: false, buildingId });
  }

  getDdlTags(buildingId: number): Observable<DdlTag[]> {
    return this.http.get<DdlTag[]>(`${this.baseUrl}/tag/building/${buildingId}`);
  }

  updateDdlMode(mode: boolean, id: number, buildingId: number): Observable<DdlMode> {
    return this.http.put<DdlMode>(`${this.baseUrl}/ddl-mode/${id}`, { enabled: mode, buildingId });
  }
}
