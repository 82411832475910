import { DISCRIMINATOR, Selectable } from '@app/shared/models/selectable.interface';
import { SimpleLampType } from '@app/shared/models/lamp-type.interface';

export interface LuminaireDriverDTO {
  id?: number;
  x: number;
  y: number;
  address: number;
  nodeId: number;
  lampTypeId: number;
  lampType: SimpleLampType;
  burnInHours: number;
  energyConsumption: number;
  conflictingAddress: boolean;
  daliTypeId: number;
  daliType: string;
  updatedAt: Date;
  daliStatus: number;
  scene: number;
  daliStates?: string[];
}

export interface SelectableLuminaire extends LuminaireDriverDTO, Selectable {}

export class LuminaireDriver implements LuminaireDriverDTO, SelectableLuminaire {
  address: number;
  burnInHours: number;
  conflictingAddress: boolean;
  daliStatus: number;
  daliType: string;
  daliTypeId: number;
  discriminator: DISCRIMINATOR;
  energyConsumption: number;
  id?: number;
  lampTypeId: number;
  lampType: SimpleLampType;
  nodeId: number;
  scene: number;
  daliStates?: string[];
  updatedAt: Date;
  x: number;
  y: number;
  isChanged: boolean;

  constructor(dto: LuminaireDriverDTO) {
    this.address = dto.address;
    this.burnInHours = dto.burnInHours;
    this.conflictingAddress = dto.conflictingAddress;
    this.daliStatus = dto.daliStatus;
    this.daliType = dto.daliType;
    this.daliTypeId = dto.daliTypeId;
    this.energyConsumption = dto.energyConsumption;
    this.id = dto.id;
    this.lampTypeId = dto.lampTypeId;
    this.lampType = dto.lampType;
    this.nodeId = dto.nodeId;
    this.scene = dto.scene;
    this.daliStates = dto.daliStates;
    this.updatedAt = dto.updatedAt;
    this.x = dto.x;
    this.y = dto.y;
    this.discriminator = DISCRIMINATOR.LUMINAIRE;
  }

  static of(dto: LuminaireDriverDTO): LuminaireDriver {
    return new LuminaireDriver({ ...dto });
  }

  static ofMultiple(dto: LuminaireDriverDTO[]): LuminaireDriver[] {
    const dtos = [];
    if (dto) {
      dto.forEach((item) => dtos.push(new LuminaireDriver({ ...item })));
    }
    return dtos;
  }

  get address16(): string {
    return this.address != null ? this.address.toString(16).toUpperCase() : 'Unmapped';
  }

  public get status(): string {
    if (this.address == null) {
      return 'UNKNOWN';
    }
    if (this.isFaulty) {
      return 'FAULTY';
    }
    return 'OK';
  }

  public get isFaulty(): boolean {
    return this.daliStates != null && this.daliStates.length > 0;
  }

  update(newX: number, newY: number): void {
    this.x = newX;
    this.y = newY;
    this.isChanged = true;
  }
}
