import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { LightingConfigurationService } from '@angularjs/or/services/LightingConfigurationService';
import { IObservableModifiable } from '@angularjs/or/util/IObservable';
import { ITagContext } from '@angularjs/or/api/query/outline/context/IContext';
import { OrPanelService } from '../or-panel/or-panel-service/OrPanelService';
import { LightingConfiguration } from '@angularjs/or/api/building/LightingConfiguration';
import angular from 'angular';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { building } from '@app/shared/services/user/user.service.stub.data';

export class OrLightingConfigurationController {
  public form;
  public isBusy: boolean;
  public isDisabled: boolean;
  public selectedNodes;
  private buildingId;
  public zones;
  public zone;
  private originalZone;
  public scenes;
  public originalScene;
  public scene;
  public maxLight;
  public originalMaxLight = 0;
  public indexedMaxLight = 0;
  public lowLight;
  public originalLowLight = 0;
  public indexedLowLight = 0;
  public minLight;
  public originalMinLight = 0;
  public indexedMinLight = 0;
  public dwellTimes;
  public dwellTime;
  public originalDwellTime;
  public lowLightTime;
  public originalLowLightTime;

  constructor(
    private nodeService: SensorNodeService,
    private lightingConfigurationService: LightingConfigurationService,
    private orPanelService: OrPanelService,
    private metricContext: IObservableModifiable<ITagContext>,
    private buildingService: IBuildingService,
    private $scope: ng.IScope
  ) {
    this.isBusy = false;
    this.selectedNodes = this.nodeService.selectedNodes.value();
    this.convertMaxLight();
    this.convertMinLight();
    this.convertLowLight();

    let lightingConfigParameters = [];
    angular.forEach(this.lightingConfigurationService.zones, function (value, key) {
      lightingConfigParameters.push({ id: key, value });
    });
    this.zones = lightingConfigParameters;

    lightingConfigParameters = [];
    angular.forEach(this.lightingConfigurationService.scenes, function (value, key) {
      lightingConfigParameters.push({ id: key, value });
    });
    this.scenes = lightingConfigParameters;

    lightingConfigParameters = [];
    angular.forEach(this.lightingConfigurationService.dwellTimes, function (value, key) {
      lightingConfigParameters.push({ id: key, value });
    });
    this.dwellTimes = lightingConfigParameters;

    this.lightingConfigurationService.updateLightingConfigPanel = (update: LightingConfiguration): void => {
      this.updatePanel(update);
    };
  }

  public $onInit(): void {
    this.buildingService.getCurrentBuilding().then((building) => {
      this.$scope.$apply(() => {
        this.buildingId = building.id;
      });
    });
  }

  updatePanel(update: LightingConfiguration): void {
    this.zone = null;
    this.scene = null;
    this.originalMaxLight = 0;
    this.originalMinLight = 0;
    this.originalLowLight = 0;
    this.indexedMaxLight = 0;
    this.indexedMinLight = 0;
    this.indexedLowLight = 0;
    this.convertMaxLight();
    this.convertMinLight();
    this.convertLowLight();
    this.dwellTime = null;
    this.lowLightTime = null;
    if (update != null) {
      if (update.zone != null) this.zone = { id: update.zone };
      if (update.scene != null) this.scene = { id: update.scene.value };
      if (update.maxLightLevel != null) {
        this.originalMaxLight = update.maxLightLevel;
        this.indexedMaxLight = update.maxLightLevel;
        this.convertMaxLight();
      }
      if (update.minLightLevel != null) {
        this.originalMinLight = update.minLightLevel;
        this.indexedMinLight = update.minLightLevel;
        this.convertMinLight();
      }
      if (update.lowLightLevel != null) {
        this.originalLowLight = update.lowLightLevel;
        this.indexedLowLight = update.lowLightLevel;
        this.convertLowLight();
      }
      if (update.dwellTime) this.dwellTime = { id: update.dwellTime };
      if (update.lowLightTime) this.lowLightTime = { id: update.lowLightTime };
    }
  }

  get selectedSensorNodes() {
    return this.selectedNodes;
  }

  convertMaxLight(): void {
    this.maxLight = this.lightingConfigurationService.convertMaxLightIdToName(this.indexedMaxLight);
  }

  convertMinLight(): void {
    this.minLight = this.lightingConfigurationService.convertMinLightIdToName(this.indexedMinLight);
  }

  convertLowLight(): void {
    this.lowLight = this.lightingConfigurationService.convertLowLightIdToName(this.indexedLowLight);
  }

  public getFactoryDefault(levelId: number): string {
    return this.lightingConfigurationService.convertLightIdToName(levelId);
  }

  public get isPanelDisabled(): boolean {
    return !this.lightingConfigurationService.isNodeConfigurable();
  }

  public setZone() {
    this.isBusy = true;
    if (this.checkIfCanSetProperty()) {
      this.lightingConfigurationService
        .updateZone(this.selectedSensorNodes[0], this.zone.id, this.buildingId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalZone = this.zone;
            this.zone = this.zones.filter((z) => z.id == this.zone.id)[0];
            this.isBusy = false;
            alert('The lighting configuration zone has been set to ' + this.zone.value);
          });
        })
        .catch((e) => {
          console.log(e);
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public resetZone() {
    this.zone = this.originalZone;
  }

  public setScene() {
    this.isBusy = true;

    if (this.checkIfCanSetProperty()) {
      this.lightingConfigurationService
        .updateScene(this.selectedSensorNodes[0], this.scene.id, this.buildingId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalScene = this.scene;
            this.scene = this.scenes.filter((s) => s.id == this.scene.id)[0];
            this.isBusy = false;
            alert('The lighting configuration scene has been set to ' + this.scene.value);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public resetScene() {
    this.scene = this.originalScene;
  }

  public setMaxLight() {
    this.isBusy = true;
    if (this.checkIfCanSetProperty()) {
      this.lightingConfigurationService
        .updateMaxLight(this.selectedSensorNodes[0], this.indexedMaxLight, this.buildingId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalMaxLight = this.indexedMaxLight;
            this.convertMaxLight();
            this.isBusy = false;
            alert('The lighting configuration Max Light level has been set to ' + this.maxLight);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public resetMaxLight() {
    this.indexedMaxLight = this.originalMaxLight;
    this.convertMaxLight();
  }

  public setMinLight() {
    this.isBusy = true;
    if (this.checkIfCanSetProperty()) {
      this.lightingConfigurationService
        .updateMinLight(this.selectedSensorNodes[0], this.indexedMinLight, this.buildingId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalMinLight = this.indexedMinLight;
            this.convertMinLight();
            this.isBusy = false;
            alert('The lighting configuration Min Light level has been set to ' + this.minLight);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public resetMinLight() {
    this.indexedMinLight = this.originalMinLight;
    this.convertMinLight();
  }

  public setLowLight() {
    this.isBusy = true;
    if (this.checkIfCanSetProperty()) {
      this.lightingConfigurationService
        .updateLowLight(this.selectedSensorNodes[0], this.indexedLowLight, this.buildingId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalLowLight = this.indexedLowLight;
            this.convertLowLight();
            this.isBusy = false;
            alert('The lighting configuration Low Light level has been set to ' + this.lowLight);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public resetLowLight() {
    this.indexedLowLight = this.originalLowLight;
    this.convertLowLight();
  }

  public setDwellLightTime() {
    this.isBusy = true;
    if (this.checkIfCanSetProperty()) {
      this.lightingConfigurationService
        .updateDwellLightTime(this.selectedSensorNodes[0], this.dwellTime.id, this.buildingId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalDwellTime = this.dwellTime;
            this.dwellTime = this.dwellTimes.filter((t) => t.id == this.dwellTime.id)[0];
            this.isBusy = false;
            alert('The lighting configuration Dwell Light Time has been set to ' + this.dwellTime.value);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public resetDwellLightTime() {
    this.dwellTime = this.originalDwellTime;
  }

  public setLowLightTime() {
    this.isBusy = true;
    if (this.checkIfCanSetProperty()) {
      this.lightingConfigurationService
        .updateLowLightTime(this.selectedSensorNodes[0], this.lowLightTime.id, this.buildingId)
        .then(() => {
          this.$scope.$apply(() => {
            this.originalLowLightTime = this.lowLightTime;
            this.lowLightTime = this.dwellTimes.filter((t) => t.id == this.lowLightTime.id)[0];
            this.isBusy = false;
            alert('The lighting configuration Low Light Time has been set to ' + this.lowLightTime.value);
          });
        })
        .catch(() => {
          this.isBusy = false;
          alert('Something went wrong!');
        });
    }
  }

  public resetLowLightTime() {
    this.lowLightTime = this.originalLowLightTime;
  }

  private checkIfCanSetProperty() {
    return (
      this.orPanelService.getPanel('lightingConfigurationFloorplan').isActive == true &&
      this.selectedSensorNodes.length > 0
    );
  }
}
