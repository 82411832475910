import { ITyped } from '../util/ITyped';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';

export class DataType implements ITyped {
  constructor(
    public value: DataType_,
    public nodeTypes: string[] = [
      SensorNode.SN3_NODE_TYPE,
      SensorNode.PASSIVE_NODE_TYPE,
      SensorNode.HIM84_NODE_TYPE,
      SensorNode.HCD405_NODE_TYPE
    ],
    public format: (value: number, suffix: string) => string = (value, suffix) =>
      value ? value.toFixed(2) + suffix : null
  ) {}

  static OCCUPANCY = new DataType('OCCUPANCY', [
    SensorNode.SN3_NODE_TYPE,
    SensorNode.HIM84_NODE_TYPE,
    SensorNode.HCD405_NODE_TYPE
  ]);
  static ENERGY_CONSUMPTION = new DataType(
    'ENERGY_CONSUMPTION',
    [SensorNode.SN3_NODE_TYPE, SensorNode.PASSIVE_NODE_TYPE],
    (value, suffix) => DataType.energyFormatter(value, suffix)
  );
  static LIGHT_LEVEL = new DataType('LIGHT_LEVEL');
  static DIM_LEVEL = new DataType('DIM_LEVEL');
  static PRESENCE = new DataType('PRESENCE', [
    SensorNode.SN3_NODE_TYPE,
    SensorNode.HIM84_NODE_TYPE,
    SensorNode.HCD405_NODE_TYPE
  ]);
  static CONNECTION_QUALITY = new DataType('CONNECTION_QUALITY', [
    SensorNode.SN3_NODE_TYPE,
    SensorNode.PASSIVE_NODE_TYPE,
    SensorNode.HIM84_NODE_TYPE,
    SensorNode.HCD405_NODE_TYPE,
    SensorNode.FUJITSU_ENV,
    SensorNode.FUJITSU_ENV_C02
  ]);
  static TEMPERATURE = new DataType('TEMPERATURE', [SensorNode.FUJITSU_ENV, SensorNode.FUJITSU_ENV_C02]);
  static PRESSURE = new DataType('PRESSURE', [SensorNode.FUJITSU_ENV, SensorNode.FUJITSU_ENV_C02]);
  static HUMIDITY = new DataType('HUMIDITY', [SensorNode.FUJITSU_ENV, SensorNode.FUJITSU_ENV_C02]);
  static FUJI_AMBIENT_LIGHT_LEVEL = new DataType('FUJI_AMBIENT_LIGHT_LEVEL', [
    SensorNode.FUJITSU_ENV,
    SensorNode.FUJITSU_ENV_C02
  ]);
  static SOUND = new DataType('SOUND', [SensorNode.FUJITSU_ENV, SensorNode.FUJITSU_ENV_C02]);
  static CO2 = new DataType('CO2', [SensorNode.FUJITSU_ENV_C02]);

  public static fromString(value: string): DataType {
    for (const dataType of this.all()) {
      if (value === dataType.toString()) {
        return dataType;
      }
    }
  }

  private static energyFormatter(value: number, suffix: string): string {
    if (!value) {
      return null;
    }

    if (value >= 1000000) {
      return (value / 1000000).toFixed(2) + ' M' + suffix;
    }

    if (value >= 1000) {
      return (value / 1000).toFixed(2) + ' k' + suffix;
    }

    if (value < 0.01) {
      return value.toExponential(2) + ' ' + suffix;
    }

    return value.toFixed(2) + ' ' + suffix;
  }

  public static all(): DataType[] {
    return [
      DataType.PRESENCE,
      DataType.OCCUPANCY,
      DataType.DIM_LEVEL,
      DataType.LIGHT_LEVEL,
      DataType.ENERGY_CONSUMPTION,
      DataType.CONNECTION_QUALITY,
      DataType.TEMPERATURE,
      DataType.PRESSURE,
      DataType.HUMIDITY,
      DataType.FUJI_AMBIENT_LIGHT_LEVEL,
      DataType.SOUND,
      DataType.CO2
    ];
  }
  public getType(): string {
    return 'DataType';
  }

  public toString(): string {
    return this.value;
  }

  public equals(other: DataType): boolean {
    return this.value == other.value;
  }
}

export type DataType_ =
  | 'OCCUPANCY'
  | 'ENERGY_CONSUMPTION'
  | 'LIGHT_LEVEL'
  | 'DIM_LEVEL'
  | 'PRESENCE'
  | 'CONNECTION_QUALITY'
  | 'TEMPERATURE'
  | 'PRESSURE'
  | 'HUMIDITY'
  | 'FUJI_AMBIENT_LIGHT_LEVEL'
  | 'SOUND'
  | 'CO2';
