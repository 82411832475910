import { NodesController } from '../nodes/NodesController';
import { BeaconSettingService } from '@angularjs/or/services/BeaconSettingService';
import { INodesParamsService } from '@angularjs/angular/app/portal/routes/nodes/NodesConfig';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { FloorService } from '@angularjs/or/services/FloorService';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import { TenantService } from '@app/shared/services/building/tenant.service';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { IObservable, IObservableModifiable } from '@angularjs/or/util/IObservable';
import { ILocationContext, ITagContext } from '@angularjs/or/api/query/outline/context/IContext';
import { IQueryOutlineBuilder } from '@angularjs/or/api/query/outline/IQueryOutlineBuilder';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { UIRefreshService } from '@angularjs/or/services/UIRefreshService';
import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';
import { BeaconSetting } from '@angularjs/or/api/building/BeaconSetting';
import angular, { ITimeoutService } from 'angular';
import { Subject } from 'rxjs';
import { FeatureService } from '@app/shared/services/feature.service';
import { TagService } from '@angularjs/or/services/TagService';
import { SecurityService } from '@angularjs/or/angular/services/SecurityService';

export class BeaconsController extends NodesController {
  public isTagPanelActive = false;
  public isConfigurationPanelActive = true;
  public isContainerPanelActive = true;
  public isPending = false;
  public selectedNodes;
  private pendingNodes: number[] = [];
  private timedOutNodes: number[] = [];
  private delayBetweenRetries = 5000; // milliseconds
  private retires = 7;
  private nodeLoadedToPanel: FloorplanSensorNode;
  public reloadNodesSub: Subject<boolean> = new Subject<boolean>();

  constructor(
    $routeParams: INodesParamsService,
    $scope: ng.IScope,
    buildingService: IBuildingService,
    floorService: FloorService,
    nodesService: SensorNodeService,
    tenantService: TenantService,
    navigationService: NavigationService,
    locationContext: IObservableModifiable<ILocationContext>,
    outlineBuilder: IObservable<IQueryOutlineBuilder>,
    mysectionInfo: NavigationSectionInfo,
    uiRefreshService: UIRefreshService,
    private timeout: ITimeoutService,
    private beaconSettingService: BeaconSettingService,
    featureService: FeatureService,
    tagService: TagService,
    tagContext: IObservableModifiable<ITagContext>,
    securityService: SecurityService
  ) {
    super(
      $routeParams,
      $scope,
      buildingService,
      floorService,
      nodesService,
      tenantService,
      navigationService,
      locationContext,
      outlineBuilder,
      mysectionInfo,
      uiRefreshService,
      featureService,
      tagService,
      tagContext,
      securityService
    );
    this.selectedNodes = this.sensorNodeService.selectedNodes.value();
  }

  public querySelectedNodes(): void {
    const nodes = this.selectedSensorNodes;
    const nodeIds = this.extractedNodeIds(nodes);
    this.timedOutNodes = [];
    this.addToPendingMultipleNodes(nodeIds);
    this.addToTimedOutNodes(nodeIds);
    this.beaconSettingService
      .querySensorNodes(nodes, this.buildingId)
      .then(() => {
        let confirmedNodeIds: number[];
        confirmedNodeIds = [];
        this.getNodeWithRetries(nodes, confirmedNodeIds, this.retires);
      })
      .catch(() => {
        alert('Something went wrong!');
        this.removeFromPendingNodes(nodeIds);
        this.removeFromTimedOutNodes(nodeIds);
      });
  }

  private extractedNodeIds(nodes: SensorNode[]): number[] {
    const nodeIds = [];
    nodes.map((node) => {
      nodeIds.push(node.id);
    });
    return nodeIds;
  }

  private removeItemsFromArray(arr: any[], itemsToRemove: any[]): any[] {
    return arr.filter((currentItem) => {
      return itemsToRemove.indexOf(currentItem) === -1 ? true : false;
    });
  }

  private getNodeWithRetries(nodes: SensorNode[], confirmedNodeIds: number[], retries: number): void {
    let nodeIds = this.extractedNodeIds(nodes);
    nodeIds = this.removeItemsFromArray(nodeIds, confirmedNodeIds);

    if (retries <= 0) {
      if (confirmedNodeIds.length) {
        this.showUpdatedInformation(confirmedNodeIds);
      } else {
        alert('The time expired with no result.');
      }
      this.removeFromPendingNodes(nodeIds);
      this.removeFromTimedOutNodes(nodeIds);
      return;
    }
    const BreakError = {};
    let doRetry = false;

    this.beaconSettingService
      .getBeaconSettingsForFloorAndNodeIds(this.floorId, nodeIds)
      .then((result) => {
        result.forEach((beaconSetting) => {
          let node;
          try {
            nodes.forEach((n) => {
              if (n.id === beaconSetting.sensorNodeId) {
                node = n;
                throw BreakError;
              }
            });
          } catch (err) {
            if (err !== BreakError) {
              throw err;
            }
          }
          if (beaconSetting && beaconSetting.updateStatus === 'CONFIRMED') {
            this.removeFromPendingNodes([beaconSetting.sensorNodeId]);
            this.updateNodeAndPanel(node, beaconSetting);
            confirmedNodeIds.push(beaconSetting.sensorNodeId);
            this.removeFromTimedOutNodes([beaconSetting.sensorNodeId]);
          } else {
            if (result) {
              this.updateNodeAndPanel(node, beaconSetting);
            }
            doRetry = true;
          }
        });
        if (doRetry) {
          this.timeout(() => {
            this.getNodeWithRetries(nodes, confirmedNodeIds, retries - 1);
          }, this.delayBetweenRetries);
        } else {
          this.showUpdatedInformation(confirmedNodeIds);
          if (nodes.length > 1) {
            this.reloadNodesSub.next(true);
          }
        }
      })
      .catch(() => {
        alert('Error in communication with the Portal');
        this.removeFromPendingNodes(nodeIds);
        this.removeFromTimedOutNodes(nodeIds);
        return;
      });
  }

  private showUpdatedInformation(confirmedNodeIds: number[]): void {
    if (confirmedNodeIds && confirmedNodeIds.length > 0) {
      if (confirmedNodeIds.length === 1 && this.selectedNodes.length == 1) {
        alert('The data was updated for node ' + confirmedNodeIds);
      } else {
        const timeOutMessage = this.timedOutNodes.length
          ? '\nThe time expired with no result for  ' + this.timedOutNodes.sort()
          : '';
        alert('The data was updated for nodes ' + confirmedNodeIds.sort() + timeOutMessage);
      }
    }
  }

  private updateNodeAndPanel(node: SensorNode, update: BeaconSetting): void {
    this.updateBeaconSettings(node, update);
    this.selectedSensorNodes.forEach((selectedNode) => {
      if (selectedNode === node) {
        this.sensorNodeService.updateBeaconSettingPanel(update);
      }
    });
  }

  private updateBeaconSettings(node: SensorNode, update: BeaconSetting): void {
    this.sensorNodeService.getRegisteredListedNode(node.id).beaconSetting = angular.copy(update);
    this.sensorNodeService.getRegisteredFloorplanNode(node.id).beaconSetting = angular.copy(update);
    node.beaconSetting = angular.copy(update);
    this.scope.$apply();
  }

  public get isSelectedNodeReady(): boolean {
    return (
      !this.isSelectedNodePending() &&
      !this.selectedSensorNodes.some((node) => {
        return !node.properlyMapped || !node.connected || node.isPassiveNode;
      })
    );
  }

  public isNodePending(id: number): boolean {
    return this.pendingNodes.indexOf(id, 0) > -1;
  }

  private addToPendingNodes(id: number): void {
    if (!this.isNodePending(id)) {
      this.pendingNodes.push(id);
    }
  }
  private addToPendingMultipleNodes(ids: number[]): void {
    ids.map((id) => {
      if (!this.isNodePending(id)) {
        this.pendingNodes.push(id);
      }
    });
  }

  private removeFromPendingNodes(ids: number[]): void {
    ids.map((id) => {
      if (this.isNodePending(id)) {
        this.pendingNodes.splice(this.pendingNodes.indexOf(id, 0), 1);
      }
    });
  }

  public isSelectedNodePending(): boolean {
    let isPending = false;
    if (this.selectedSensorNodes.length > 0) {
      isPending = this.selectedSensorNodes.some((node) => this.isNodePending(node.id));
    }
    return isPending;
  }

  public get selectedSensorNodes(): FloorplanSensorNode[] {
    const nodes = this.selectedNodes;
    if (nodes.length === 1) {
      if (nodes[0] !== this.nodeLoadedToPanel) {
        this.nodeLoadedToPanel = nodes[0];
        this.sensorNodeService.updateBeaconSettingPanel(this.nodeLoadedToPanel.beaconSetting);
      }
    } else {
      if (this.nodeLoadedToPanel != null) {
        this.nodeLoadedToPanel = null;
        this.sensorNodeService.updateBeaconSettingPanel(null);
      }
    }
    return nodes;
  }
  private addToTimedOutNodes(ids: number[]): void {
    ids.forEach((id) => {
      if (this.isNodePending(id)) {
        this.timedOutNodes.push(id);
      }
    });
  }
  private removeFromTimedOutNodes(ids: number[]): void {
    ids.forEach((id) => {
      if (!this.isNodePending(id)) {
        this.timedOutNodes.splice(this.timedOutNodes.indexOf(id, 0), 1);
      }
    });
  }
}
