import { Injectable } from '@angular/core';
import { DdlMode, DdlTag, DdlSetting } from '@app/shared/models/ddl-setting.interface';
import { Observable } from 'rxjs';
import { DdlResource } from '@app/shared/resources/ddl.resource';

@Injectable({
  providedIn: 'root'
})
export class DdlService {
  constructor(private ddlResource: DdlResource) {}

  public getDdlSetting(buildingId: number): Observable<DdlSetting> {
    return this.ddlResource.getDdlSetting(buildingId);
  }

  public createDdlSetting(ddlSetting: DdlSetting): Observable<DdlSetting> {
    return this.ddlResource.createSetting(ddlSetting);
  }

  public updateDdlSetting(ddlSetting: DdlSetting, settingId: number): Observable<DdlSetting> {
    return this.ddlResource.updateDdlSetting(ddlSetting, settingId);
  }

  public getDdlMode(buildingId: number): Observable<DdlMode> {
    return this.ddlResource.getDdlMode(buildingId);
  }

  public getDdlTags(buildingId: number): Observable<DdlTag[]> {
    return this.ddlResource.getDdlTags(buildingId);
  }

  public createDdlMode(buildingId: number): Observable<DdlMode> {
    return this.ddlResource.createDdlMode(buildingId);
  }

  public updateDdlMode(mode: boolean, id: number, buildingId: number): Observable<DdlMode> {
    return this.ddlResource.updateDdlMode(mode, id, buildingId);
  }
}
